import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'

const ForgotPassword = () => {

    const [OTPRequest,setOTPRequest] = useState(false);
    const [OTPVerfied,setOTPVerfied] = useState(false);

    async function sendOTP (){
      return ;
    }
    async function changePassword(){
      return ;
    }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                    {!OTPRequest
                    ?
                    <CForm>
                    <h1>Forgot Password ?
                    </h1>
                    <p className="text-medium-emphasis">Enter your email for OTP verification</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput placeholder="Username" autoComplete="username" />
                    </CInputGroup>
                    
                    <CRow>
                      <CCol xs={6}>
                      <Link to='/admin'>
                        <CButton color="primary" className="px-4">
                          Send OTP
                        </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                : !OTPVerfied ?
                <CForm>
                    <h1>Forgot Password ?
                    </h1>
                    <p className="text-medium-emphasis">Enter OTP recieved on your E-mail</p>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    
                    <CRow>
                      <CCol xs={6}>
                      <Link to='/admin'>
                        <CButton color="primary" className="px-4">
                          Verify OTP
                        </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                :
                <CForm>
                    <h1>Forgot Password ?
                    </h1>
                    <p className="text-medium-emphasis">Reset Password</p>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="New Password"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Confirm Password"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    
                    <CRow>
                      <CCol xs={6}>
                      <Link to='/admin'>
                        <CButton color="primary" className="px-4">
                          Submit
                        </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>

                }
                  
                  
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ForgotPassword;

// i18n.js
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          Filter: 'Filter',
          selectFilter: 'Select Filter',
          Dashboard: 'Dashboard',
          Users: 'Users',
          Year: 'Year',
          Month: 'Month',
          Week: 'Week',
          Custom: 'Custom',
          Periods: 'Periods',
          Apply: 'Apply',
          Payments: 'Payments',
          Logout: 'Logout',
          Income: 'Income',
          'No. of searches': 'No. of searches',
          User: 'User',
          'Total Payments': 'Total Payments',
          Balance: 'Balance',
          Action: 'Action',
          'Are you sure you want to delete this user?':
            'Are you sure you want to delete this user?',
          No: 'No',
          Yes: 'Yes',
          'All Payments': 'All Payments',
          'Transaction ID': 'Transaction ID',
          'Payment Method': 'Payment Method',
          Amount: 'Amount',
          Status: 'Status',
          'Delete User': 'Delete User',
          Name: 'Name',
          'Current Balance': 'Current Balance',
          'E-mail': 'E-mail',
          'Update Changes': 'Update Changes',
          'Search History': 'Search History',
          'Payment History': 'Payment History',
          Hastags: 'Hastags',
          Platform: 'Platform',
          'Deposit ID': 'Deposit ID',
          Date: 'Date',
          'Sign In to your account': 'Sign In to your account',
          'Loading...': 'Loading...',
        },
      },
      ko: {
        translation: {
          Filter: '필터',
          selectFilter: '필터 선택',
          Dashboard: '계기반',
          Users: '사용자',
          Year: '년도',
          Month: '월',
          Week: '주',
          Custom: '관습',
          Periods: '미문',
          Apply: '적용',
          Payments: '결제',
          Logout: '로그 아웃',
          Income: '소득',
          'No. of searches': '검색 횟수',
          User: '사용자',
          'Total Payments': '총 결제금액',
          Balance: '균형',
          Action: '행동',
          'Are you sure you want to delete this user?': '이 사용자를 삭제하시겠습니까?',
          No: '아니요',
          Yes: '예',
          'All Payments': '모든 결제',
          'Transaction ID': '거래 ID',
          'Payment Method': '결제수단',
          Amount: '양',
          Status: '상태',
          'Delete User': '사용자 삭제',
          Name: '이름',
          'Current Balance': '현재의 균형',
          'E-mail': '이메일',
          'Update Changes': '변경 사항 업데이트',
          'Search History': '검색 기록',
          'Payment History': '결제 내역',
          Hastags: '해시태그',
          Platform: '플랫폼',
          'Deposit ID': '입금ID',
          Date: '날짜',
          Login: '로그인',
          'Sign In to your account': '귀하의 계정에 로그인하세요',
          'Loading...': '로드 중...',
        },
      },
    },
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n

import React, { Component, Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import Dashboard from './views/dashboard/Dashboard'
import ForgotPassword from './views/pages/forgot-password/ForgotPassword'
import { connect } from 'react-redux'
import PrivateRoute from './routes/routesCheck'
import { store } from './redux/store'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Admin = React.lazy(() => import('./views/pages/admin/Admin'))

  const App = ({user})=>{
    console.log(user);
    const state = store.getState();
    const details = state?.user?.details;
    let token = details?.access;

    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
          <Route exact path="/" name="/" element={token ?<Navigate to='/admin' />:<Navigate to='/login' />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="/admin/*" name="Home" element={
                <PrivateRoute>
                  <DefaultLayout />
                </PrivateRoute>
              } />
            <Route exact path="/forgot-password" name="Forgot Password" element={<ForgotPassword />} />
            <Route exact path="*" name="Page 404" element={<Page404 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
}
const stateToProps=state=>{
  return {
    user:state.user
  }
}
export default connect(stateToProps)(App)

import React, { useEffect, useState, useTransition } from 'react'
import {
  CRow,
  CCol,
  CWidgetStatsA,
  CCard,
  CCardHeader,
  CCardBody,
  CFormSelect,
  CFormLabel,
  CFormInput,
  CButton,
} from '@coreui/react'
import { getStyle } from '@coreui/utils'
import { CChartLine } from '@coreui/react-chartjs'
import { store } from 'src/redux/store'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { userSetDetails } from 'src/redux/user/user.action'
import { faL } from '@fortawesome/free-solid-svg-icons'

const WidgetsDropdown = () => {
  const [weeklyRange, setWeeklyRange] = useState([])
  const [dashData, setDashData] = useState([])
  const [data, setData] = useState({
    weeklyUserData: [],
    weeklyIncomeData: [],
    weeklySearchData: [],
    weeklyInstagramSearchData: [],
    weeklyYoutubeSearchData: [],
  })
  const [selectFilter, setSelectFilter] = useState('sf')
  const [period, setPeriod] = useState(0)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    getWidgetData()
  }, [])

  async function getWidgetData() {
    const state = store.getState()
    const details = state.user.details
    let token = details?.access

    return fetch('https://srv1.keywordlit.com/api/superuser-dashboard/', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            navigate('/login')
            dispatch(userSetDetails(null))
            throw new Error('404 Not found at getWidget')
          } else throw new Error('Response not okay at getWidget')
        }
        return response.json()
      })
      .then((data) => {
        if (data.Message === 'get the data successfully') {
          const weeklyUserData = data?.data?.weekly_user?.map((week) => {
            return Object.values(week[Object.keys(week)[0]])[0]
          })
          const weeklySearchData = data?.data?.weekly_search?.map((week) => {
            return Object.values(week[Object.keys(week)[0]])[0]
          })
          const weeklyIncomeData = data?.data?.weekly_income?.map((week) => {
            return Object.values(week[Object.keys(week)[0]])[0]
          })
          const weeklyInstagramSearch = data?.data?.Instagram_search_history?.map((week) => {
            return Object.values(week[Object.keys(week)[0]])[0]
          })
          const weeklyYoutubeSearch = data?.data?.Youtube_search_history?.map((week) => {
            return Object.values(week[Object.keys(week)[0]])[0]
          })
          setData((prev) => ({
            ...prev,
            weeklyUserData: weeklyUserData,
            weeklyIncomeData: weeklyIncomeData,
            weeklySearchData: weeklySearchData,
            weeklyInstagramSearchData: weeklyInstagramSearch,
            weeklyYoutubeSearchData: weeklyYoutubeSearch,
          }))
          setDashData(data?.data)
          console.log(data?.data)
        } else {
          console.log('Error')
        }
      })
      .catch((error) => console.error(error, 'Catch Block'))
  }
  useEffect(() => {
    // console.log(data);
    getWeeklyRanges()
  }, [data])

  const getWeeklyRanges = () => {
    const today = new Date()
    const weeklyRanges = []
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    for (let i = 5; i >= 0; i--) {
      const endDate = new Date(today.getTime() - i * 7 * 24 * 60 * 60 * 1000)
      const startDate = new Date(endDate.getTime() - 6 * 24 * 60 * 60 * 1000)
      const startMonth = months[startDate.getMonth()]
      const endMonth = months[endDate.getMonth()]
      const rangeString = `${startDate.getDate()}-${startMonth} to ${endDate.getDate()}-${endMonth}`
      weeklyRanges.push(rangeString)
    }
    setWeeklyRange(weeklyRanges.reverse())
  }
  const handleApplyFilter = () => {
    const state = store.getState()
    const details = state.user.details
    let token = details?.access
    const body = new FormData()
    body.append('date_filter', selectFilter)
    body.append('periods', period)

    return fetch('https://srv1.keywordlit.com/api/superuser-dashboard-new/', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: body,
    })
      .then((response) => {
        console.log('response ==== ', response)
        // if (!response.ok) {
        //   if (response.status === 404) {
        //     navigate('/login')
        //     dispatch(userSetDetails(null))
        //     throw new Error('404 Not found at getWidget')
        //   } else throw new Error('Response not okay at getWidget')
        // }
        return response.json()
      })
      .then((data) => {
        if (data.Message === 'get the data successfully') {
          // const weeklyUserData = data?.data?.weekly_user?.map((week) => {
          //   return Object.values(week[Object.keys(week)[0]])[0]
          // })
          // const weeklySearchData = data?.data?.weekly_search?.map((week) => {
          //   return Object.values(week[Object.keys(week)[0]])[0]
          // })
          // const weeklyIncomeData = data?.data?.weekly_income?.map((week) => {
          //   return Object.values(week[Object.keys(week)[0]])[0]
          // })
          // const weeklyInstagramSearch = data?.data?.Instagram_search_history?.map((week) => {
          //   return Object.values(week[Object.keys(week)[0]])[0]
          // })
          // const weeklyYoutubeSearch = data?.data?.Youtube_search_history?.map((week) => {
          //   return Object.values(week[Object.keys(week)[0]])[0]
          // })
          // setData((prev) => ({
          //   ...prev,
          //   weeklyUserData: weeklyUserData,
          //   weeklyIncomeData: weeklyIncomeData,
          //   weeklySearchData: weeklySearchData,
          //   weeklyInstagramSearchData: weeklyInstagramSearch,
          //   weeklyYoutubeSearchData: weeklyYoutubeSearch,
          // }))
          // setDashData(data?.data)
          console.log(data?.data)
        } else {
          console.log('Error')
        }
      })
      .catch((error) => console.error(error, 'Catch Block'))
  }

  return (
    <CCard className="mb-4">
      <CCard>
        <CCardHeader>{t('Filter')}</CCardHeader>
        <CCardBody>
          <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            <div>
              <CFormSelect
                aria-label={t('selectFilter')}
                onChange={(e) => setSelectFilter(e?.target?.value)}
                options={[
                  { label: `${t('selectFilter')}`, value: 'sf' },
                  { label: `${t('Year')}`, value: 'year' },
                  { label: `${t('Month')}`, value: 'month' },
                  { label: `${t('Week')}`, value: 'week' },
                  { label: `${t('Custom')}`, value: 'custom' },
                ]}
              />
            </div>
            {selectFilter !== 'sf' && (
              <div>
                {/* <CFormLabel htmlFor="exampleDropdownFormEmail1">Email address</CFormLabel> */}
                <CFormInput
                  type="number"
                  id="Periods"
                  placeholder={t('Periods')}
                  onChange={(e) => setPeriod(e?.target?.value)}
                />
              </div>
            )}
            <div>
              <CButton
                color="light"
                // disabled={selectFilter === 'sf' && period == 0}
                onClick={handleApplyFilter}
              >
                {t('Apply')}
              </CButton>
            </div>
          </div>
        </CCardBody>
      </CCard>
      <CCardHeader>{'Dashboard'}</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm={12} lg={12}>
            <CWidgetStatsA
              className="mb-4 "
              color="light"
              value={<div style={{ color: 'black' }}>{dashData?.total_user}</div>}
              title={<div style={{ color: 'black' }}>{t('Users')}</div>}
              chart={
                <CChartLine
                  className="mt-3 mx-3 mb-3 p-1"
                  style={{ height: '100px' }}
                  data={{
                    labels: weeklyRange,
                    datasets: [
                      {
                        label: 'Users',
                        backgroundColor: 'black',
                        borderColor: 'grey',
                        pointBackgroundColor: getStyle('--cui-dark'),
                        data: data?.weeklyUserData,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        grid: {
                          display: true,
                          drawBorder: false,
                        },
                        ticks: {
                          display: true,
                        },
                      },
                      y: {
                        min: 0,
                        max: Math.max(...data?.weeklyUserData) * 1.15,
                        display: true,
                        grid: {
                          display: true,
                        },
                        ticks: {
                          display: true,
                        },
                      },
                    },
                    elements: {
                      line: {
                        borderWidth: 2,
                        tension: 0.4,
                        borderColor: 'black',
                      },
                      point: {
                        radius: 4,
                        hitRadius: 10,
                        hoverRadius: 4,
                      },
                    },
                  }}
                />
              }
            />
          </CCol>
          <CCol sm={12} lg={12}>
            <CWidgetStatsA
              className="mb-4"
              color="light"
              value={
                <div style={{ color: 'black' }}>
                  ${dashData.total_diposite_amount} | {dashData.total_diposite}
                </div>
              }
              title={<div style={{ color: 'black' }}>{t('Payments')}</div>}
              chart={
                <CChartLine
                  className="mt-3 mx-3"
                  style={{ height: '100px' }}
                  data={{
                    labels: weeklyRange,
                    datasets: [
                      {
                        label: 'Amount',
                        backgroundColor: 'transparent',
                        borderColor: 'rgba(255,255,255,.55)',
                        pointBackgroundColor: getStyle('--cui-info'),
                        data: data?.weeklyIncomeData,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        grid: {
                          display: true,
                          drawBorder: false,
                        },
                        ticks: {
                          display: true,
                        },
                      },
                      y: {
                        min: 0,
                        max: Math.max(...data?.weeklyIncomeData) * 1.15,
                        display: true,
                        grid: {
                          display: true,
                        },
                        ticks: {
                          display: true,
                        },
                      },
                    },
                    elements: {
                      line: {
                        borderWidth: 1,
                      },
                      point: {
                        radius: 4,
                        hitRadius: 10,
                        hoverRadius: 4,
                      },
                    },
                  }}
                />
              }
            />
          </CCol>
          <CCol sm={12} lg={12}>
            <CWidgetStatsA
              className="mb-4"
              color="light"
              value={
                <div style={{ color: 'black' }}>
                  {dashData?.total_Instagram_search + dashData?.total_Youtube_search}
                </div>
              }
              title={<div style={{ color: 'black' }}>{t('No. of searches')}</div>}
              chart={
                <CChartLine
                  className="mt-3"
                  style={{ height: '100px' }}
                  data={{
                    labels: weeklyRange,
                    datasets: [
                      {
                        label: 'Total Searches',
                        backgroundColor: 'grey',
                        borderColor: 'rgba(255,255,255,.55)',
                        data: data?.weeklySearchData,
                        fill: true,
                      },
                      {
                        label: 'Instagram Searches',
                        backgroundColor: 'yellow',
                        borderColor: 'rgba(50,255,155,.55)',
                        data: data?.weeklyInstagramSearchData,
                        fill: true,
                      },
                      {
                        label: 'Youtube Searches',
                        backgroundColor: 'red',
                        borderColor: 'rgba(100,200,200,.55)',
                        data: data?.weeklyYoutubeSearchData,
                        fill: true,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        display: true,
                      },
                      y: {
                        min: 0,
                        max: Math.max(...data?.weeklySearchData) * 1.15,
                        display: true,
                        grid: {
                          display: true,
                        },
                        ticks: {
                          display: true,
                        },
                      },
                    },
                    elements: {
                      line: {
                        borderWidth: 2,
                        tension: 0.4,
                      },
                      point: {
                        radius: 0,
                        hitRadius: 10,
                        hoverRadius: 4,
                      },
                    },
                  }}
                />
              }
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  )
}

export default WidgetsDropdown

const { Route, Link, Navigate } = require("react-router-dom")
const { store } = require("src/redux/store")


const PrivateRoute =({children})=>{

    const state = store.getState();
    const details = state?.user?.details;
    let token = details?.access;

    if(token) {
        return children
    }
    return (<Navigate to='/'/>)

}
export default PrivateRoute;
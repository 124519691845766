import { applyMiddleware, combineReducers, compose, configureStore } from '@reduxjs/toolkit'
import userReducer from './user/user.reducer'
import appReducer from './appReducer';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';


const middleware=[];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;

const persistConfig ={
  key:'auth',
  storage,
  whitelist:['user']
}

const persistedReducer = persistReducer(persistConfig, appReducer)

const store = configureStore({
  reducer :persistedReducer},
  composeEnhancers(applyMiddleware(...middleware))
);

const persistor = persistStore(store);
export {store,persistor} 

const { UserActionTypes } = require("./user.actionTypes");

const INTIAL_STATE = {
    details: null
}

const userReducer = (state =INTIAL_STATE,action)=>{
    switch(action.type){
        case UserActionTypes.USER_SET_DETAILS:
            return {
                ...state,
                details : action.payload
            }
        default:
            return state;
    }
}
export default userReducer;

const INTIAL_STAT= {
    sidebarShow: true,
  }

const sideReducer = (state =INTIAL_STAT,{type ,...rest})=>{
    switch (type){
        case 'set':
            return {
                ...state,
                ...rest
            }
        default:
        return state
    }
}
export default sideReducer;